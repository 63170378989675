'use strict';
import $ from 'jquery'
import autosize from 'autosize'
import select2 from 'select2'
select2($);

document.addEventListener('turbo:load', vendor_init);
document.addEventListener('turbo:render', vendor_init);
  
function vendor_init() {
// $(document).ready(function () {

  /*------------------------------------------------
      Autosize Textarea (Autosize)
  ------------------------------------------------*/
  if($('.textarea-autosize')[0]) {
    autosize($('.textarea-autosize'));
  }

  /*------------------------------------------------
      Popovers (Bootstrap)
  -------------------------------------------------*/
  if($('[data-toggle="popover"]')[0]) {
      $('[data-toggle="popover"]').popover();
  }


  /*------------------------------------------------
      Tooltip (Bootstrap)
  -------------------------------------------------*/
  if($('[data-toggle="tooltip"]')[0]) {
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' }).on('click', function() { $(this).tooltip('hide') });
  }

  if($('a[data-toggle!="popover"], b[title], span[title]')[0]) {
    $('a[data-toggle!="popover"], b[title], span[title]').tooltip({ trigger: 'hover' }).on('click', function() { $(this).tooltip('hide') });
  }
}