import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';

document.addEventListener('turbo:load', function() {
  flatpickr("[data-datePicker]", {
    enableTime: false,
    dateFormat: "d/m/Y",
  });

  flatpickr("[data-date-time-picker]", {
    enableTime: true,
    dateFormat: "d/m/Y H:i",
    time_24hr: true,
  });

  // New configuration for blocking past dates and times
  flatpickr("[data-block-past-datetime-picker]", {
    enableTime: true,
    dateFormat: "d/m/Y H:i",
    minDate: "today",
    time_24hr: true,
    minTime: new Date().toLocaleTimeString('pt-BR', { hour12: false }),
    // Disable times in the past for today
    onDayCreate: function(dObj, dStr, fp, dayElem) {
      if (dayElem.dateObj.setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
        const currentTime = new Date();
        fp.config.minTime = currentTime.toLocaleTimeString('pt-BR', { hour12: false });
      } else {
        fp.config.minTime = "00:00";
      }
    }
  });
});
