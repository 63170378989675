import { Controller } from "@hotwired/stimulus";
import "select2";
import "select2/dist/js/i18n/pt-BR.js"

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.selectTarget = $(this.element).find('select');
    this.placeholder = this.selectTarget.data('placeholder') || 'Selecione uma opção...';
    if (this.hasUrlValue) {
      this.ajaxInit();
    } else {
      this.init();
    }
  }

  init() {
    this.selectTarget.select2({
      placeholder: this.placeholder,
      minimumInputLength: 0,
      width: '100%',
      language: 'pt-BR',
      allowClear: true
    });
  }

  ajaxInit() {
    this.selectTarget.select2({
      placeholder: this.placeholder,
      minimumInputLength: 0,
      width: '100%',
      language: 'pt-BR',
      allowClear: true,
      ajax: {
        url: this.urlValue, // URL da API
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            pgq: params.term, // O texto buscado
            json_select: true, // Parâmetro fixo
            page: params.page || 1 // Página atual
          };
        },
        processResults: (data, params) => {
          // Processa os resultados da API
          params.page = params.page || 1;

          return {
            results: data.results, // Resultados
            pagination: {
              more: data.pagination.more // Indica se há mais resultados
            }
          };
        },
        cache: true
      },
    });
  }

  disconnect() {
    this.selectTarget.select2('destroy'); // Limpa o Select2 ao desconectar
  }
}
