import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const mapElement = document.getElementById('map');
    const latitudeInput = document.getElementById('ong_latitude');
    const longitudeInput = document.getElementById('ong_longitude');
    const addressInput = document.getElementById('ong_address');
    const loadingIndicator = document.getElementById('loading-indicator');

    if (mapElement && latitudeInput && longitudeInput) {
      const initialLatitude = parseFloat(latitudeInput.value) || -8.0630802;
      const initialLongitude = parseFloat(longitudeInput.value) || -34.8980516;
      const zoomLevel = 13;

      const map = L.map(mapElement).setView([initialLatitude, initialLongitude], zoomLevel);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      let marker = initialLatitude && initialLongitude ? L.marker([initialLatitude, initialLongitude]).addTo(map) : null;

      map.on('click', function (e) {
        const { lat, lng } = e.latlng;

        latitudeInput.value = lat.toFixed(8);
        longitudeInput.value = lng.toFixed(8);

        if (marker) {
          marker.setLatLng([lat, lng]);
        } else {
          marker = L.marker([lat, lng]).addTo(map);
        }

        marker.bindPopup(`Latitude: ${lat.toFixed(8)}, Longitude: ${lng.toFixed(8)}`).openPopup();

        
        loadingIndicator.style.display = 'block';

        fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`)
          .then(response => response.json())
          .then(data => {
            if (data && data.display_name) {
              const addressParts = data.display_name.split(',').map(part => part.trim());
              let shortenedAddress = ``;

              if (!isNaN(parseInt(addressParts[0]))) {
                const number = addressParts[0];
                const street = addressParts[1];
                const city = addressParts[addressParts.length - 8];
                const state = addressParts[addressParts.length - 4];
                const postalCode = addressParts[addressParts.length - 2];

                shortenedAddress = `${street}, ${number} - ${city}, ${state}, ${postalCode}`;
              } else {
                const street = addressParts[0];
                const neighborhood = addressParts[1];
                const city = addressParts[addressParts.length - 8];
                const state = addressParts[addressParts.length - 4];
                const postalCode = addressParts[addressParts.length - 2];

                shortenedAddress = `${street}, ${neighborhood}, ${city}, ${postalCode}`;
              }
              addressInput.value = shortenedAddress;
            } else {
              addressInput.value = 'Endereço não encontrado';
            }
          })
          .catch(() => {
            addressInput.value = 'Erro ao buscar endereço';
          })
          .finally(() => {
            loadingIndicator.style.display = 'none';
          });
      });
    }
  }
}
