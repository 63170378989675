import $ from 'jquery'
import Rails from "@rails/ujs"
import Swal from 'sweetalert2'

document.addEventListener('turbo:load', directUploadError);
document.addEventListener('turbo:render', directUploadError);

function directUploadError() {
  addEventListener("direct-upload:error", function(event) {
    event.preventDefault();
    
    $('button[data-disable-with]').each(function () {
      Rails.enableElement(this);
      $(this).removeAttr('disabled');
    });

    Swal.fire({
      title: 'Atenção',
      text: 'Houve um erro ao enviar os anexos. Recarregue a página e tente novamente',
      type: 'warning',
      confirmButtonText: 'Ok'
    });
  });
}