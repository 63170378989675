import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptBRLocale from '@fullcalendar/core/locales/pt-br'
import listPlugin from '@fullcalendar/list'

export default class extends Controller {
  static values = {
    events: String
  }

  static targets = ['calendar', 'loader']

  connect() {
    this.showLoader()
    setTimeout(() => {
      this.initializeCalendar()
    }, 300)
  }

  disconnect() {
    if (this.calendar) {
      this.calendar.destroy()
    }
  }

  showLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.style.display = 'block'
    }
  }

  hideLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.style.display = 'none'
    }
  }

  initializeCalendar() {
    const events = this.getEventsSource()
    if (!events) {
      console.warn('No events source configured for FullCalendar')
      this.hideLoader()
      return
    }

    this.calendar = new Calendar(this.calendarTarget, {
      locale: ptBRLocale,
      plugins: [dayGridPlugin, listPlugin],
      dayMaxEventRows: true,
      initialView: this.defaultView,
      events,
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,listWeek'
      },
      loading: this.handleLoading.bind(this),
      eventContent: this.renderEventContent
    })

    this.calendar.render()
    this.hideLoader()
  }

  get defaultView() {
    return this.isMobile ? 'listWeek' : 'dayGridMonth'
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  getEventsSource() {
    return this.hasEventsValue ? this.eventsValue : null
  }

  handleLoading(isLoading) {
    if (this.hasLoaderTarget) {
      this.loaderTarget.style.display = isLoading ? 'block' : 'none'
    }
  }

  renderEventContent(arg) {
    return {
      html: `
        <div class="fc-event-title">${arg.event.title}</div>
        <div class="fc-event-description trix-content">${arg.event.extendedProps.description || ''}</div>
        <div class="fc-event-description">Organização: ${arg.event.extendedProps.ong || ''}</div>
      `
    }
  }
}
