import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["allDay", "endDate"]

  connect() {
    this.toggleEndDate()
  }

  toggleEndDate() {
    this.endDateTarget.disabled = this.allDayTarget.checked
    this.endDateTarget.value = ""
  }
}
