import { Controller } from "@hotwired/stimulus"
import lightbox from 'lightbox2';

// Connects to data-controller="lightbox"
export default class extends Controller {
  connect() {
    console.log('Lightbox connected');
    lightbox.init();
  }

  disconnect() {
    console.log('Lightbox disconnected');
    lightbox.end();
  }
}
