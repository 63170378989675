import Trix from "trix"

addExtraTrixAttributes()

addEventListener("trix-initialize", function (event) {
    new RdmTrixExtensions(event.target)
})

class RdmTrixExtensions {
  constructor(element) {
    this.element = element

    this.insertAlignButtons()
  }

  insertAlignButtons() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforebegin", this.alignButtonsTemplate)
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=text-tools]")
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=block-tools]")
  }

  get toolbarElement() {
    return this.element.toolbarElement
  }

  get alignButtonsTemplate() {
    return `
      <span class="trix-button-group trix-button-group--alignment-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="Align Left" tabindex="-1">Align Center</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>
      </span>
    `
  }
}

function addExtraTrixAttributes() {
  Trix.config.blockAttributes.alignLeft = {
    tagName: 'align-left',
    parse: false,
    nestable: false,
    exclusive: true,
  };
  
  Trix.config.blockAttributes.alignCenter = {
    tagName: 'align-center',
    parse: false,
    nestable: false,
    exclusive: true,
  };
  
  Trix.config.blockAttributes.alignRight = {
    tagName: 'align-right',
    parse: false,
    nestable: false,
    exclusive: true,
  };
}


class AlignCenter extends HTMLElement { constructor() { super(); } }
class AlignLeft extends HTMLElement { constructor() { super(); } }
class AlignRight extends HTMLElement { constructor() { super(); } }

customElements.define("align-center", AlignCenter)
customElements.define("align-left", AlignLeft)
customElements.define("align-right", AlignRight)