'use strict';
import $ from 'jquery'
/*------------------------------------------------
    Page Loader
-------------------------------------------------*/
document.addEventListener('turbo:load', function() {
// $(window).on('load', function () {
    if ($('.page-loader')[0]) {
        setTimeout(function () {
            $('.page-loader').fadeOut();
        }, 90);
    }
});
$( document ).on('turbolinks:visit', function() {
// $(window).on('load', function () {
    if ($('.page-loader')[0]) {
        $('.page-loader').fadeIn();
    }
});

document.addEventListener('turbo:load', theme_init);
document.addEventListener('turbo:render', theme_init);

function theme_init() {
    /*------------------------------------------------
        Theme Switch
    -------------------------------------------------*/
    $('body').on('change', '.theme-switch input:radio', function () {
        var theme = $(this).val();

        $('body').attr('data-ma-theme', theme);
    });


    /*------------------------------------------------
        Search
    -------------------------------------------------*/

    // Active Stat
    $('body').off('focus', '.search__text').on('focus', '.search__text', function () {
        $(this).closest('.search').addClass('search--focus');
    });

    // Clear
    $('body').off('blur', '.search__text').on('blur', '.search__text', function () {
        $(this).val('');
        $(this).closest('.search').removeClass('search--focus');
    });


    /*------------------------------------------------
        Sidebar toggle menu
    -------------------------------------------------*/
    $('body').off('click', '.navigation__sub > a').on('click', '.navigation__sub > a', function (e) {
        e.preventDefault();

        $(this).parent().toggleClass('navigation__sub--toggled');
        $(this).next('ul').slideToggle(250);
    });


    /*------------------------------------------------
        Form group blue line
    -------------------------------------------------*/
    if($('.form-group--float')[0]) {
        $('.form-group--float').each(function () {
            var p = $(this).find('.form-control').val()

            if(p && !p.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });

        $('body').off('blur', '.form-group--float .form-control').on('blur', '.form-group--float .form-control', function(){
            var i = $(this).val();

            if (i.length == 0) {
                $(this).removeClass('form-control--active');
            }
            else {
                $(this).addClass('form-control--active');
            }
        });

        $(this).find('.form-control').change(function () {
            var x = $(this).val();

            if(!x.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });
    }


    /*------------------------------------------------
        Clock
    -------------------------------------------------*/
    if($('.time')[0]) {
        var newDate = new Date();
        newDate.setDate(newDate.getDate());

        setInterval( function() {
            var seconds = new Date().getSeconds();
            $('.time__sec').html(( seconds < 10 ? '0' : '' ) + seconds);
        },1000);

        setInterval( function() {
            var minutes = new Date().getMinutes();
            $('.time__min').html(( minutes < 10 ? '0' : '' ) + minutes);
        },1000);

        setInterval( function() {
            var hours = new Date().getHours();
            $('.time__hours').html(( hours < 10 ? '0' : '' ) + hours);
        }, 1000);
    }
}